import { render, staticRenderFns } from "./tecList.vue?vue&type=template&id=0e5fcb26&scoped=true"
import script from "./tecList.vue?vue&type=script&lang=js"
export * from "./tecList.vue?vue&type=script&lang=js"
import style0 from "./tecList.vue?vue&type=style&index=0&id=0e5fcb26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e5fcb26",
  null
  
)

export default component.exports