<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="download(scope.row, scope.$index)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination> -->
    </div>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'tecList',
    components: {
      pagination,
      breadcrumb
    },
    data() {
      let title = '技术规范'
      return {
        allSelData: [],
        breadStaticData:['系统管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit
        },
        urlName: 'fileCode',
        tableHead: [{
          prop: 'fileName',
          label: '文件名称',
        }],
        initForm: {
        },
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      download(data){
        if(!data.fileUrl){
          this.message('获取路径有误，请稍后重试', "error")
          return
        }
        if(data.fileType == 'pdf'){
          utils.downloadPdf(data.fileUrl, `${data.fileName || '技术规范'}.${data.fileType}`)
        }else{
          utils.downImage(data.fileUrl, data.fileName || '技术规范')
        }
      },
      async initTable(){
        this.getData()
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName]('501002').then(res => {
          _this.loading = false
          if(res.code == 200){
            // let lists = res.data.list || []
            let lists = res.data || []
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            // _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
.down-load-btn{
  color: #f16123;
  font-size: 14px;
  text-decoration: none;
}
</style>